const messages = {
  sl: {
    sprinxFormsValidation: {
      required: 'Polje je obvezno!',
      string: 'Mora biti besedilni niz!',
      stringEmpty: 'Ne sme biti prazno!',
      stringMin: 'Dolžina mora biti večja ali enaka {expected} znakov!',
      stringMax: 'Dolžina mora biti manjša ali enaka {expected} znakov!',
      stringLength: 'Dolžina mora biti {expected} znakov dolga!',
      stringPattern: 'Ne ustreza zahtevanemu vzorcu!',
      stringContains: "Potrebno vključevati tekst '{expected} '!",
      stringEnum: 'Ne ustreza nobeni od dovoljenih vrednosti!',
      stringNumeric: 'Biti mora številka!',
      number: 'Biti mora številka!',
      numberMin: 'Biti mora večja ali enaka {expected}!',
      numberMax: 'Biti mora manjša ali enaka {expected}!',
      numberEqual: 'Biti mora enaka s {expected}!',
      numberNotEqual: 'Ne mora biti enaka s {expected}!',
      numberInteger: 'Biti mora cela številka!',
      numberPositive: 'Številka mora imeti pozitivno vrednost!',
      numberNegative: 'Številka mora imeti negativno vrednost.',
      array: 'To mora biti seznam!',
      arrayEmpty: 'Ne mora biti prazen seznam!',
      arrayMin: 'Mora biti vsaj {number, plural, one {1 izdelek} few {{number} izdelkov} other {{number} izdelkov}}!',
      arrayMax:
        'Ne more vsebovati več kot {number, plural, one {1 izdelek} few {{number} izdelkov} other {{number} izdelkov}}!',
      arrayLength:
        'Mora vsebovati točno {expected, plural, one {1 izdelek} few {{number} izdelkov} other {{number} izdelkov}}!',
      arrayContains: "Mora vsebovati izdelek '{expected}'!",
      arrayEnum: "Vrednost '{expected}' se ne ujema z nobeno od dovoljenih vrednosti!",
      boolean: 'Mora biti "da" ali "ne"!',
      function: 'To mora biti funkcija!',
      date: 'To mora biti datum!',
      dateMin: 'Mora biti večji ali enak {expected}!',
      dateMax: 'Mora biti manjši ali enak {expected}!',
      forbidden: 'Polje je prepovedano!',
      email: 'E-mal ni pravilen!',
      url: 'Ne pravilni URL naslov!',
      object: 'Mora biti tipu Object!',
      arrayReducedEquals: 'Povzeta vrednost ne ustreza {expected}!',
      phoneNumber: "Telefonska številka mora biti v veljavni mednarodni obliki! '+ XXX XXX XXX XXX '!",
      dateGt: 'Datum mora biti večji od {expected}!',
      dateGte: 'Datum mora biti večji ali enak {expected}!',
      dateLt: 'Datum mora biti manjši od {expected}!',
      dateLte: 'Datum mora biti manjši ali enak  {expected}!',
    },
  },
};

export default messages;
