const messages = {
  sl: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Košarica',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametri',
        },
      },
      productAvailability: {
        format: {
          available: 'Na zalogi',
          moreThanX: 'Več kot {min} kosov na zalogi',
          exactCount: '{value} kosov na zalogi',
          notAvailable: 'Ni na voljo',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} več',
      },
      relatedProducts: {
        title: 'Sorodni izdelki',
      },
      productAccessories: {
        title: 'Dodatke',
      },
      productDetailImageGallery: {
        noImage: 'Brez fotografiji',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kdaj bom prejel blago?',
      },
    },
  },
};

export default messages;
