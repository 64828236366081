const messages = {
  sl: {
    orders: {
      actions: {
        addNewOrder: 'Dodaj naročilo',
        all: 'Vsa naročila',
      },
      listPage: {
        title: 'Naročila ',
        table: {
          filter: {
            number: 'Številka',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Datum naročanja',
            number: 'Številka',
            offer: 'Ponudba',
            opportunity: 'Priložnost',
            owner: 'Lastnik',
            state: 'Status',
            total: 'Skupaj',
            totalUserCurrency: 'Skupaj v {currency}',
          },
        },
      },
      order: {
        newEntity: 'Novo naročilo',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Skupna provizija',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Valuta',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Datum naročanja',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Številka',
          helperText: 'Za samodejno dopolnjevanje številk pustite prazno.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Ponudba',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Priložnost',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Lastnik',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Trgovska marža',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Predmet ',
          discount: {
            label: 'Popust',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Provizija',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Velikost paketa',
          },
          listPrice: {
            label: 'Cena v ceniku',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Cena v prodaji',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Izdelek            ',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Količina',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Trgovska marža',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Za izdelek skupaj',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Enota',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Skupaj',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {brez izdelkov} one {1 izdelek} few {{number} izdelki} other {{number} izdelkov}}',
      },
      myOrdersScoringBlog: {
        title: 'Moja naročila',
      },
      ordersList: 'Naročila ',
    },
  },
};

export default messages;
