import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AgreementField from '@sprinx/cvo-eshop-ui-common/components/AgreementField';
import AgreementFieldLink from '@sprinx/cvo-eshop-ui-common/components/AgreementFieldLink';
import clsx from 'clsx';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    label: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    link: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'AgreementFieldGdpr' },
);

function AgreementFieldGdpr({ classes: pClasses, className, error, onChange, value, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      <AgreementField value={value} onChange={onChange} error={error} {...rest}>
        <Typography variant='body1' className={classes.label}>
          Strinjam se z obdelavo
          <AgreementFieldLink {...rest} to='/info/varstvo-osebnih-podatkov' className={classes.link}>
            osebnih podatkov
          </AgreementFieldLink>
          za pošiljanje glasila.
        </Typography>
      </AgreementField>
    </div>
  );
}

AgreementFieldGdpr.propTypes = {
  classes: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

AgreementFieldGdpr.defaultProps = {
  classes: undefined,
  className: undefined,
  error: undefined,
  onChange: undefined,
};

export default AgreementFieldGdpr;
