import PropTypes from 'prop-types';
import comOrdersMessages from './react-com-orders/sl';
import comMessages from './react-com/sl';
import formsMessages from './react-forms/sl';
import compMessages from './react-mui-components/sl';
import tablesMessages from './react-mui-tables/sl';
import shoppingCartMessages from './react-shopping-cart/sl';
import messages from './sl';

// const rename = ({ sr }) => ({ hr: sr });

function MessagesSl({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesSl.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesSl;
