const messages = {
  sl: {
    reactMuiTables: {
      selected: 'izbrano',
      filterhide: 'Skrij filter',
      filtershow: 'Prikaži filter',
      refresh: 'Ponastavitev',
      filter: {
        actions: {
          search: 'Išči',
          resetFilter: 'Ponastavi filter',
          addCondition: 'Dodaj pogoj',
        },
      },
      standardOperators: {
        equals: 'naravnost',
        contains: 'vsebuje',
        between: {
          label: 'vmes',
          from: 'od  ',
          to: 'do  ',
        },
        oneOf: 'eden od',
      },
      labelRowsPerPage: 'Vrstic na stran:',
    },
  },
};

export default messages;
