const messagesSL = {
  sl: {
    parameters: {
      yes: 'DA',
    },
    com: {
      phase: { label: 'Označitev' },
      product: {
        ean: { label: 'EAN', helperText: '', helperTextPreview: '' },
        sku: { label: 'Koda izdelka', helperText: '', helperTextPreview: '' },
        manufacturer: { label: 'Proizvajalec', helperText: '', helperTextPreview: '' },
        pn: { label: 'Številka dela', helperText: '', helperTextPreview: '' },
        warranty: { label: 'Garancija', months: 'mesecev' },
      },
      totalAmount: { label: 'Skupaj', helperText: '' },
    },
    imageGallery: { thumbnailsMore: '+ {number} še več' },
    orders: {
      actions: { all: 'Vsa naročila' },
      myOrdersScoringBlog: { title: 'Moja naročila' },
      ordersList: 'Naročila ',
      phase: {
        1: 'Prejeto',
        '01-new': 'Prejeto',
        10: 'Obravnava se',
        20: 'Čakanje na plačilo',
        30: 'Plačano',
        98: 'Poravnano',
        99: 'Preklicano',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Številka paketa',
            phase: 'Stanje',
            paid: 'Plačano',
            total: 'Brez DDV',
            totalWithTax: 'Skupaj',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Košarica',
        buyButton: 'Poslati naročilo',
        emptyBasket: 'Vaša košarica je prazna',
        buyThere: 'Sem že nakupoval/a tukaj',
        login: 'Želim se prijaviti',
        notFill: 'Podrobnosti dostave niso izpolnjene',
        fill: 'Izpolnite podatke',
        change: 'Spremeniti podatke',
        popupButton: 'Prikaži na zemljevidu',
        payment: { title: 'NAČIN PLAČILA', free: 'Brezplačno', changePaymentBtn: 'SPREMENITI PLAČILA' },
        shipping: {
          title: 'NAČIN DOSTAVE',
          free: 'Brezplačno',
          changeShippingBtn: 'SPREMENITI PREVOZA',
          popup: { title: 'Informacije', text: 'Dostava na naslov neposredno k vam s kurirsko šlužbo {service}' },
        },
      },
      actions: { all: 'Vse košarice' },
      myBasketsScoringBlog: { title: 'Shranjene nakupovalne košarice' },
      cartSummaryParameters: { caption: 'Koda izdelka' },
    },
    productDetailSpagettyNavigation: {
      description: 'Opis',
      parameters: 'Parametre',
      package: 'Vsebina paketa',
      fotoAndVideo: 'Slika in video',
      alternatives: 'Alternative',
      alternativesText: 'Na žalost trenutno nimamo zamenjave za izdelek, ki ste ga izbrali.',
      questions: 'Vprašanja',
      productDescriptionNotAvailable: 'Opis izdelka ni na voljo',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: { title: 'Ne zamudite posebnih ponudb', inputText: 'Vaš elektronski naslov', button: 'Prejemati' },
    },
    globalNavigation: {
      home: 'Domov',
      shopping: 'Ob nakupu',
      orders: 'Naročila ',
      shoppingLists: 'Nakupovalni seznami',
      articles: 'Novice',
    },
    homePageWhy: {
      0: { title: 'Nakupujte varno', content: 'Smo preverjena stranka portala Heureka in imamo certifikat APEK.' },
      1: { title: 'Izdajati mesta', content: 'Blago lahko prevzamete tudi na več kot 200 mestih za izdajo.' },
      2: {
        title: 'Blago v lastnem skladišču',
        content: 'Na zalogi imamo 98% celotnega asortimana in pripravljen je za takojšnjo odpremo.',
      },
      3: {
        title: 'Brezplačen prevoz',
        content: 'Z nakupom izbranih digitalnih fotoaparatov in objektivov dobite BREZPLAČNO pošiljanje po Sloveniji.',
      },
      title: 'ZAKAJ OD NAS?',
    },
    startPage: { title: 'Glavna stran', gotoShopping: 'Pojdi v nakupovanje' },
    shoppingCart: {
      actions: {
        buy: 'V KOŠARICO',
      },
      buyBtn: 'V KOŠARICO',
      alternatives: 'ALTERNATIVE',
      shipping: { changePoint: 'Spreminjati' },
      agreements: { termsRequired: 'Morate se strinjati s pogoji poslovanja.' },
      validation: { someProductNotAvailable: 'Nekatere izdelke niso na zalogi v zadostnih količinah.' },
    },
    appCartSummaryProductParameters: { title: 'Koda izdelka:' },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Najbolj priporočano',
        name: 'Ime',
        priceAsc: 'Od najvišje cene',
        priceDesc: 'Od najnižje cene',
      },
    },
    orderStatuses: { storno: 'Preklic', synchronized: 'Poslano', waiting: 'Čaka sinhronizacijo' },
    orderPreview: { items: 'Izdelki   ' },
    cartRegisterForm: { title: 'Obrazec za registracijo' },
    cartPayment: { title: 'NAČIN PLAČILA' },
    cartPreSummaryPage: { title: 'Povzetek košarice', actions: { nextStep: 'Strinjam se in nadaljuj' } },
    cartCompanyForm: { description: 'Izpolnite, če kupujete blago za podjetje' },
    cartDeliveryForm: { description: 'Izpolnite, če se naslov za dostavo razlikuje od naslova za izstavitev računa' },
    productAvailabilityFieldPreviewWithPopup: { button: 'Kdaj dobim naročeno blago?' },
    productDetailStockInfo: {
      available: 'Izdelek imamo fizično v našem distribucijskem skladišču.',
      external: 'Izdelek je na poti do našega skladišča',
      notAvailable: 'Blago začasno ni na voljo',
      labels: { free: 'BREZPLAČNA DOSTAVA', showroom: 'RAZSTAVLJENO V SHOWROOMU' },
    },
    productGifts: {
      btnName: 'DARILO',
      giftValue: 'V vrednosti',
    },
    homepage: {
      productOfMonth: 'Izdelki meseca',
      catalogFavorite: 'Najbolj priljubljene kategorije',
      hotTips: 'Vroči nasveti',
      recommended: 'Priporočamo',
    },
    productQuestion: {
      firstName: 'Ime',
      lastName: 'Priimek',
      email: 'E-mail',
      subject: 'Predmet ',
      question: 'Vprašanje',
      gdpr: 'S pošiljanjem vprašanja se strinjam z obdelavo osebnih podatkov, z namenom, da dobim odgovor na vprašanje.',
      gdprLink: 'GDPR povezava',
      button: 'Pošljite vprašanje',
      subjectContent: 'Vprašanje o izdelku: {product}, kod: {sku}',
      title: 'Morate se strinjati z obdelavo osebnih podatkov.',
      success: 'Obrazec je bil poslan',
    },
    lastVisitedProducts: 'Nedavno obiskani izdelki',
    productAlternativeProducts: 'Alternativa',
    productAccessoryProducts: 'Dodatki',
    productReplacementProducts: 'Dodatek',
    productAccessories: 'Dodatek',
    productReplacements: 'Zamenjava',
    appProductDetailTabs: {
      description: 'Opis',
      parameters: 'Parametri',
      package: 'Vsebina paketa',
      query: 'Vprašanje',
      media: 'Slika in video',
      alternatives: 'Alternativa',
      accessories: 'Dodatki',
      replacements: 'Dodatki',
      form: { enum: '!!!! ENUM VREDNOST' },
    },
    cartSummaryPage: {
      title: 'Povzetek pred naročilom',
      actions: { continueShopping: 'Nadaljujte z nakupovanjem', saveOrder: 'Ustvari naročilo' },
      expectedDeliveryDate: { label: 'Zahtevani datum dostave', helperText: '', helperTextPreview: '' },
      noteForSeller: { label: 'Opomba za dostavljalca', helperText: '', helperTextPreview: '' },
      paymentGateway: { label: 'Prehod za plačila', helperText: '', helperTextPreview: '' },
    },
    userProfile: {
      title: 'Moj račun',
      content: 'Vsebina',
      contactInfo: {
        newsletter: { title: 'Newsletter', content: 'Želim prejemati novice Digiexpert.si' },
        title: 'Kontaktni podatki',
      },
      orders: { title: 'Zgodovina naročil' },
      invoices: { title: 'Moji računi' },
      myOrders: 'Moje naročila',
      myInvoices: 'Moji računi',
      logout: 'Odjaviti se',
      login: 'Prijaviti se',
      favorites: { title: 'Priljubljene' },
      changePassword: {
        title: 'Sprememba gesla',
        fields: {
          login: 'Login (ni mogoče spremeniti)',
          originalPassword: 'Izvirno geslo',
          newPassword: 'Novo geslo',
          confirmNewPassword: 'Potrditev gesla',
        },
        button: 'Shraniti spremembe',
      },
    },
    orderSavedThankPage: {
      title: 'Zahvaljujemo se za vaše naročilo {orderNumber}',
      thankMessage: {
        email: 'info@digiexpert.hr',
        phoneNumber: '',
        template:
          'Preverite svoj e-poštni nabiralnik, da potrdite svoje naročilo. E-poštno sporočilo shranite za lastno uporabo. Vse podatke o svojem naročilu najdete v spodnjem delu.',
      },
      order: 'Številko naročila:',
      payment: 'Plačilo dne:',
      cancelled: 'Preklicano',
      accepted: 'Prejeto',
      pending: 'V teku',
      paid: 'Plačano',
      actions: { back: 'Nazaj na glavno stran', detail: 'Povzetek naročila', continue: 'Nadaljujte z nakupovanjem' },
      decline: 'Zavrnjeno',
    },
    shoppingLists: {
      title: 'Nakupovalni seznami',
      actions: { saveAsShoppingList: 'Shrani kot nakupovalni seznam', importShoppingListToCart: 'Uvoz' },
    },
    searchResultPage: { search: 'Iskani izraz', results: 'Rezultati iskanja' },
    homePageArticles: { title: 'Novice', button: 'Prikaži vse članke' },
    loginPage: {
      title: 'Prijava',
      forgotPassword: 'Reset gesla',
      email: 'E-mail',
      sendEmail: 'Pošlji e-mail',
      info: 'Prejeli boste e-mail s povezavo do spremembe gesla',
      password: 'Geslo',
      notSame: 'ni enaka',
      newPassword: 'Novo geslo',
      confirmPassword: 'Potrditev gesla',
      changePassword: 'Sprememba gesla',
    },
    productPrice: {
      withoutTax: 'brez DDV',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Naročite {today} in odposlali vam bomo v roku {minDays}-{maxDays} dni.',
        today: 'Naročite danes pred {time} in še danes vam odpošljemo.',
        todayWord: 'danes',
        tomorrow: 'Naročite {today} in odpošljemo vam ga jutri.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Naročite {today} in odpošljemo ga {day}.',
          1: 'v ponedeljek',
          2: 'ob torkih',
          3: 'v sredo',
          4: 'v četrtek',
          5: 'v petek',
          6: 'v soboto',
          0: 'v nedeljo',
        },
      },
      allDescription: 'POPOLNI OPIS IZDELKA',
      demoProduct: { text: 'KUPITI CENEJŠE za:' },
      productNote: {
        title: 'KUPITI CENEJŠE za: 2.150,00€',
        note: 'Note 2 – Dodatni opis stanja ali podobni podatki o izdelku, zakaj ima popust in kaj je na robe …',
      },
      // productDetailInfo: { productCode: 'Koda izdelka: ', guarantee: 'Garancija: ' },
      adornments: {
        notForSale: 'Ni naprodaj posebe',
        usedProduct: 'Bazar',
        freeGift: 'Brezplačno darilo',
        freeShipping: 'Brezplačna dostava',
        eol: 'Zaključena prodaja',
        demo: 'Predstavitveni izdelek',
        sale: 'Popust',
      },
      disponibility: {
        primaryLessThan: 'NA ZALOGI manj kot {count} kosov',
        primaryMoreThan: 'NA ZALOGI {count, plural, zero {eden kos} other {{count} in več kosov}}',
        secondaryLessThan: 'NA ZALOGI v pripravljalnem skladišču manj kot {count} kosov',
        secondaryMoreThan:
          'NA ZALOGI v pripravljalnem skladišču {count, plural, zero {eden kos} other {{count} in več kosov}}',
        external: 'Izdelek je na poti do našega skladišča',
        unavailable: 'Izdelek ni na voljo',
        showroom: '(razstavljeno v Shoowroomu)',
        soldout: 'Izdelek je razprodan',
        'on-way': {
          d: '',
          m: 'Na poti za {from, plural, one {minutu} two {minute} other {minut}}',
          M: 'Na poti za {from, plural, one {mesec} two {meseca} few {mesecev} other {mesecev}}',
          dRange: 'Na poti za {to, plural, few {{from}-{to} dni} other {{from}-{to} dni}}',
          mRange: 'Na poti za {from}-{to, plural, one {dan} few {dni} other {dni}}',
          MRange: 'Na poti {from}-{to, plural, one {mesec} two {meseca} few {mesecev} other {mesecev}}',
          date: '{datum}',
        },
        time: 'Na poti za {count, plural, zero {jeden kus} other {{count} in več kosov}}',
      },
    },
    comparePage: { title: 'Primerjaj', removeButton: 'Odstraniti izdelek', nothing: 'Ni izdelkov za primerjavo' },
    filter: { reset: 'Ponastavi filter', search: 'Išči' },
    orderTermsAndConditions: { select: { label: 'Pogoji naročila', helperText: '', helperTextPreview: '' } },
    orderDetailPage: { actions: { import: 'Dodaj v košarico' } },
    invoiceBlogPreview: { title: 'Moji računi', button: 'Vsi računi' },
    userProfilePage: { logout: 'Odjava', setting: 'Nastavitve', passwordChange: 'Sprememba gesla' },
    appBarActionRegion: { changeRegion: 'Spremeni jezik', label: 'Zapri' },
    subCategories: { title: 'Podkategorije' },
    aboutShoppingPage: {
      title: 'VSE O NAKUPU',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONSKO ZAPISANJE PRIHODKOV',
      informationDuty: 'INFORMACIJSKA DOLŽNOST',
      processingOfPersonalData: 'OBDELAVI OSEBNIH PODATKOV',
      cashback: 'CASHBACK',
      delivery: 'DOSTAVA',
      availability: 'RAZPOLOŽLJIVOST',
      methodOfPayment: 'NAČIN PLAČILA',
      termsAndConditions: 'PRAVILA IN POGOJI',
      termsOfAppeal: 'PRITOŽBENI POGOJI',
      contractwWithdrawal: 'ODSTOP OD POGODBE',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Vaša davčna številka je preverjena, cene se zaračunajo brez DDV.',
        viesNotVerified: 'Vaša davčna številka ni preverjena ali ne izpolnjujete pogojev za nakup brez DDV. ',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'PODATKI PLAČNIKA',
      pleaseComplete: 'PROSIMO IZPOLNITE',
      closeButton: 'Zapri',
    },
    cartPromoCodes: {
      title: 'UPORABI PROMOCIJSKO KODO',
      textFieldPlaceholder: 'PROMOCIJSKA KODA',
      textFieldHelperText: 'Na naročilo je možno uporabiti samo eno promocijsko kodo',
      applyPromoCodeButton: 'UPORABI',
    },
    cookiesBar: {
      agreeBtn: 'Strinjam se',
      title: 'OBVESTILO O PIŠKOTKIH NA NAŠI SPLETNI STRANI',
      text: 'Naše podjetje uporablja na ti spletni strani svoje piškotke (tako imenovane nujne piškotke) in piškotke tretjih oseb. Na podlagi teh datotek in interesa prilagodimo spletne strani tako, da ustrezajo pričakovanjem strank, da optimiziramo naše ponudbe in preprečimo nevarnost goljufij. Brez nekaterih potrebnih piškotkov spletna stran ne more pravilno delovati. S klikom na gumb "Sprejmi vse", se strinjate z njihovo uporabo, vključno z informacijami o vedenju spletnega mesta. Soglasje lahko kadar koli prekličete.',
    },
    cartFormLogin: {
      btnSend: 'Pošlji',
      button: 'Prijavi se',
      title: 'Prijava',
      forgottenPasswordBtn: 'Pozabili ste geslo?',
      text1: 'Enkratno geslo vam bomo poslali na naveden elektronski naslov.',
      text2: 'Po prijavi nastavite novo geslo.',
      error: 'Napačno uporabniško ime ali geslo.',
    },
    cartSummartSection: { title: 'POVZETEK NAROČILA' },
    appCartSummarySubjectsList: { title: 'ARTIKEL', text: 'Ime/opis' },
    newsletterText: {
      text1: 'Potrebujete svetovanje?',
      text2: 'Lahko nas pokličete vsak delovni dan od 8. do 16. ure.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(brez DDV)',
      priceLabel: 'CENA/KOS',
      amount: 'Količina',
      totalPriceLabel: 'SKUPAJ',
      totalPrice: 'Skupaj',
      submitButton: 'ODDAJ NAROČILO',
    },
    cartSummarySubjectsListItemDirectPreview: { amountTitle: 'kos' },
    cartSummaryListItem: { freeTitle: '- Brezplačno', amountTitle: 'ks' },
    sxCartNotes: { note: 'Ne služi navedbi datuma dobave', addNote: 'Dodati opombo' },
    productDetail: {
      promoCode: {
        title: 's promocijsko kodo:',
        validTo: 'velja do:',
      },
      productSendLink: { copyLinkBtn: 'Kopirati povezavo', sendLinkByEmail: 'Poslati povezavo po e-pošti' },
      productDetailBarInformation: {
        addToFavourites: 'Dodaj med priljubljene',
        compareProduct: 'Primerjati izdelek',
        sendLink: 'Poslati povezavo',
        serviceList: 'Seznam storitev',
        questions: 'Vprašanja',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'ŽELIM RAČUN NA PODJETJE',
        otherDeliveryInfo: 'DOSTAVI NA DRUG NASLOV',
        otherDeliveryInfoAlert: 'Zavezanec za DDV ne more uporabiti drugega naslova za dostavo.',
      },
      companyNameLabel: 'Podjetje',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Priimek',
      streetLabel: 'Ulica',
      cityLabel: 'Mesto',
      zipLabel: 'Poštna številka',
      companyIDLabel: 'Davčna številka',
      countryLabel: 'Država',
    },
    cartFormPersonal: {
      label: 'Naslovi za dostavo',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Priimek',
      streetLabel: 'Ulica, hišna številka',
      pscLabel: 'Poštna številka',
      cityLabel: 'Mesto',
      countryLabel: 'Država',
      phoneLabel: 'Telefonska številka',
      emailLabel: 'Elektronski naslov',
      passwordLabel: 'Geslo',
      controlPasswordLabel: 'Nadzorno geslo',
    },
    cartFormInvoice: {
      labelDesktop: 'PODATKI PLAČNIKA / (podatki bodo izpisani na računu)',
      labelMobile: 'Kontaktni podatki',
      companyLabel: 'Podjetje',
      password: 'Geslo',
      confirmPassword: 'Preverjanje gesla',
      email: 'Elektronski naslov',
      firstName: 'Ime',
      lastName: 'Priimek',
      countryLabel: 'Država',
      phone: 'Telefonska številka',
      phoneError: 'Telefonska številka ni pravilna',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Hišna številka',
      zipLabel: 'Poštna številka',
      icoLabel: 'IČ',
      dicLabel: 'Davčna številka',
      country: 'Država',
      cityLabel: 'Mesto',
      registration: 'Želim odpreti račun',
      registrationNote: 'Odprite račun in lahko spremljate svoja naročila,pošiljke in plačila.',
      button: 'Shraniti spremembe',
    },
    page404: {
      title: 'Stran žal ne obstaja...',
      subTitle: 'Nadaljujte na glavno stran.',
      button: 'Nazaj na glavno stran.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Razvrsti po:',
      byName: 'Ime',
      byPrice: 'Cena',
      onlyStock: 'Samo na zalogi',
      resetFilter: 'Prekliči',
      displayCountOfProducts: 'Izdelkov:',
      search: 'Iskati',
      clear: 'Izbriši',
      ok: 'OK',
    },
    priceRangeSliderField: { title: 'Cenovni razpon' },
    productListMultipleFilterMobileDialog: { headerTitle: 'Filtriraj po' },
    productListMultipleFilterMobile: { title: 'FILTRIRATI' },
    cartCompany: {
      companyName: 'Podjetje',
      regNo: 'Davčna številka podjetja',
      taxNo: 'ID številka za DDV',
      taxNoPlaceholder: 'SI00000000',
      warningMessage: {
        warning: 'Pomembno.',
        message: 'V primeru dostave na naslov, ki ni naslov podjetja, bo naročilo obračunano z DDV.',
      },
    },
    searchResultsPage: {
      match: 'Za {searchText} smo našli {resultCountFormatted} rezultatov.',
      nomatch: 'Za {searchText} nismo našli nobenega rezultata.',
      matchingCategories: 'Najdene kategorije',
      button: 'Nazaj na glavno stran.',
    },
    selectMultipleFilter: { totalChipsLabel: 'Izbrano {total} izdelkov' },
    cartDelivery: {
      firstName: 'Ime',
      lastName: 'Priimek',
      companyName: 'Podjetje',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Hišna številka',
      zipLabel: 'Poštna številka',
      country: 'Država',
      cityLabel: 'Mesto',
    },
    cartNotes: {
      shippingLabel: 'Dodati opombo prevozniku',
      shippingNote: 'Opomba za prevoznika',
      sellerLabel: 'Dodati opombo prodajalcu',
      sellerNote: 'Opomba za prodajalca',
      loginNote: 'Če že imate račun pri nas, se obrazec ob prijavi avtomatsko izpolni.',
    },
    cartCountryShipping: {
      title: 'OBMOČJE DOSTAVE',
      label: 'Izbira države vpliva na način plačila in prevoza ter datum dostave',
    },
    cartAddress: {
      bllingAddress: 'Naslov za pošiljanje računa',
      deliveryAddress: 'Dostavni naslov',
      pickUpPointAddress: 'Naslov prevzemnega mesta',
    },
    cartAgreements: {
      customerVerified:
        'Ne strinjam se s prejemom vprašalnika o preverjanju zadovoljstva s strani kupcev, ki bo pomagal izboljšati vaše storitve.',
    },
    loginDialog: { username: 'Uporabniško ime', password: 'Geslo' },
    sprinxFormsValidation: {
      confirmPassword: 'Vnesena gesla nista enaka',
      invalidPostalCode: 'Navedena poštna številka ni v veljavni obliki',
      usernameIsNotFree: 'Uporabnik s tem imenom je že registriran.',
      emailEmpty: 'E-mal ni pravilen!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Dodaj nov račun',
        all: 'Vsi računi',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Številka',
            invoiceDate: 'Datum računa',
            dueDate: 'Do datuma',
            total: 'Brez DDV',
            totalWithTax: 'Skupaj',
            billingAddress: 'Naslov za pošiljanje računa',
            orderNumber: 'Številka naročila',
            unpaidAmount: 'Neplačani znesek',
          },
          filter: {
            number: 'Številka',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Vrstnice računov',
          columns: {
            priceUnit: 'Cena/ks',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Skupni znesek',
      tax: 'DDV',
      withoutTax: 'Skupna cena brez DDV',
    },
    compare: {
      addToCompare: 'Izdelek je bil dodan v primerjalnik',
      removeToCompare: 'Izdelek je bil odstranjen iz primerjalnika',
      price: 'Cena',
      ean: 'ean',
    },
    favorite: {
      addToFavorite: 'Izdelek je bil dodan v priljubljene',
      removeFromFavorite: 'Izdelek je bil odstranjen iz priljubljenih',
    },
    registration: {
      important: 'POMEMBNO!',
      note: 'Enkratno geslo vam bomo  poslali po elektronski pošti. Po prijavi vnesite novo geslo.',
      gdprAgreement: 'Morate se strinjati z obdelavo osebnih podatkov',
      submitButton: 'Registriraj se',
      fail: 'Registracija ni bila uspešna, poskusite ponovno kasneje',
      success: 'Registracija je bila uspešna',
      title: 'Registracija',
      dialog: 'Če še nimate računa pri nas, se lahko registrirajte',
      here: 'tukaj',
    },
    appBarActionGlobalSearchComposed: { label: 'Išči produkte' },
    appBarActions: { about: 'Vse o nakupu', aboutHref: '/vse-o-nakupu', compare: 'Primerjaj' },
    appBarActionUser: { title: 'Prijaviti se' },
    footer: { copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. vse pravice pridržane' },
    appArticlesPage: { title: 'Besedila' },
    errorPage: { title: 'Nekaj je na robe.', button: 'Glavna stran' },
    cartSummary: { withoutTax: 'brez DDV' },
    renewPasswordForm: {
      success: 'Na vaš e-poštni naslov je bilo poslano novo geslo.',
      emailNotFound: 'E-pošta ni bila najdena!',
      error: 'Nekaj je na robe',
    },
    cartSubjectListItemQuantityWarning: 'Največja količina naročila je {count} izdelkov',
  },
};

export default messagesSL;
