const messages = {
  sl: {
    app: {
      about: 'O aplikaciji',
      actions: {
        add: 'Dodaj  ',
        addNamed: 'Dodaj {name}',
        remove: 'Izbriši',
        removeNamed: 'Izbriši {name}',
        removeConfirmation: 'Ali ste prepričani, da želite izbrisati?',
        more: 'Več',
        less: 'Manj',
        download: 'Prenos podatkov',
        save: 'Shrani              ',
        cancel: 'Prekliči ',
        close: 'Zapri',
        moreFormFields: 'Več polj',
        export: 'Izvoz',
        edit: 'Uredi',
        createdSuccessMessage: 'Uspešno ustvarjeno.',
        savedSuccessMessage: 'Uspešno shranjeno.',
        deletedSuccessMessage: 'Uspešno izbrisano.',
        detail: 'Detajl',
        next: 'Naprej',
        back: 'Nazaj     ',
        start: 'Začetek',
        whatNext: 'Kaj sledi ...',
        confirm: 'Potrdilo',
        confirmRemove: 'Ali ste prepričani, da želite izbrisati?',
        search: 'Išči',
        userProfile: 'Uporabniški profil',
        tenantProfile: 'Profil vašega CRM-ja',
        imports: 'Uvoz',
        fileImports: 'Uvozi datotek',
        settings: 'Nastavitve',
        logout: 'Odjava',
        login: 'Prijava',
        filterList: 'Filtrirat seznam',
        excel: 'Excel',
        refresh: 'Ponastavi',
        clear: 'Počisti',
        agree: 'Strinjam se         ',
        disagree: 'Ne strinjam se',
        makeCopy: 'Naredi kopijo',
        fullscreen: 'Prikaži na celi zaslon',
        fullscreenClose: 'Zapri prikazovanje na celi zaslon',
        copy: 'Kopiraj',
      },
      messages: {
        confirmRemove: 'Ali ste prepričani, da želite izbrisati ta izdelek?',
        errorOccurred: 'Prišlo do napake',
        createdSuccess: 'Ustvarjanje je bilo uspešno.',
        savedSuccess: 'Shranjevanje je bilo uspešno.',
        deletedSuccess: 'Brisanje je bilo uspešno.',
        uploadSuccess: 'Datoteka je bila uspešno naložena.',
        uploadError: 'Napaka pri prenosu datoteke.',
        uploadInProgress: 'Nalaganje ...',
        uploadedFiles:
          '{number, plural, one {1 naložena datoteka} few {{number} naloženih datotek} other {{number} datotek je bilo naloženih}}',
      },
      error: 'Napaka! ',
      validations: {
        isRequired: 'Polje {field} je obvezno.',
        minLengthIsRequired:
          'Potrebno je vsaj  {number, plural, one {1 položka} few {{number} položky} other {{number} izdelkov}}.',
        minValue: 'Najvišja dovoljena vrednost je {value}.',
        maxValue: 'Najvišja dovoljena vrednost je {value}.',
        passwordMatchError: 'Gesla se ne ujemajo',
        invalidEmail: 'E-mail je neveljaven.',
        invalidPhoneNumber: "Telefonska številka mora biti v veljavni mednarodni obliki! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Neveljavna številka',
        invalidUrl: 'Neveljaven URL naslov.',
        positive: 'Številka mora imeti pozitivno vrednost.',
        negative: 'Številka mora imeti negativno vrednost.',
      },
      login: {
        title: 'Prijava v Sprinx CRM',
        username: 'Uporabniško ime',
        password: 'Geslo',
        loginButton: 'Prijava',
        error: 'Prijava ni bila uspešna. Uporabniškega imena ali gesla ni mogoče najti',
        pleaseRelogin: 'Zdi se, da niste prijavljeni. Prosimo, poskušajte se prijaviti ponovno.',
      },
      days: '{count, plural, one {1 den} few {{formattedCount} dni} other {{formattedCount} dni}}',
      overdue: 'zamuda {count, plural, one {1 den} few {{formattedCount} dni} other {{formattedCount} dni}}',
      newItem: 'Novi',
      anonymizing: {
        confirm: {
          title: 'Potrdite anonimizacijo',
          content: 'Ali ste prepričani, da želite anonimizirati osebne podatke?',
        },
        button: 'Anonimiziranje osebnih podatkov',
      },
      appMenu: {
        discover: 'Odkrijte',
      },
      missingType: 'Prosimo, v administraciji aplikacije najprej določite {typeName}.',
      missingTypeForEntityType:
        'Prosimo, v administraciji aplikacije najprej določite {typeName} za {forEntityTypeName}.',
      emptyScoringBlog: 'Moduli, katerih vsebina ali nastavitve so prikazane na tej strani, niso aktivirani.',
    },
    components: {
      buttons: {
        showOnMap: 'Prikaži na zemljevidu',
      },
      filters: {
        filterButtonSubmit: 'Išči',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Dodaj pogoj',
        resetFilter: 'Ponastavi filter',
        operators: {
          contains: {
            0: 'vsebuje',
            _: 'vsebuje',
          },
          containsexact: {
            0: 'vsebuje natančno',
            _: 'vsebuje natančno',
          },
          notcontains: {
            0: 'ne vsebuje ',
            _: 'ne vsebuje ',
          },
          betweennumber: {
            0: 'od  ',
            1: 'do  ',
            _: 'vmes',
          },
          betweendate: {
            0: 'od  ',
            1: 'do  ',
            _: 'vmes',
          },
          equal: {
            0: 'je enak',
            _: 'je enak',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Izdelkov skupaj: {number}',
        nextPage: 'Naštej {number} izdelkov',
        emptyMessage: 'Ni najdenih izdelkov.',
      },
      selects: {
        emptyOption: 'Nobena',
        emptyMessage: 'Ni najdenih izdelkov.',
      },
      tables: {
        labelRowsPerPage: 'Vrstic na strani',
        sortBy: 'Razvrsti po {field}',
        recordsChanged: 'Nekateri zapisi so bili dodani ali spremenjeni.',
      },
      textField: {
        errorMsgWrapper: 'Napaka: {msg}',
      },
      dateField: {
        invalidDateFormat: 'Nepravilna oblika datuma.',
        invalidDate: 'Neznano',
        maxDateMessage: 'Datum ne sme biti večji od največjega datuma',
        minDateMessage: 'Datum ne sme biti manjši od najnižjega datuma',
        today: 'Danes',
      },
      detailNavigation: {
        scoringblog: 'Scoring Blog',
        card: 'Kartica',
        relations: 'Seja ',
      },
      manipulationInfo: {
        created: 'Ustvarjeno',
        updated: 'Spremenjeno',
        removed: 'Izbrisano',
      },
    },
  },
};

export default messages;
