const messages = {
  sl: {
    shoppingCart: {
      actions: {
        addToCart: 'Vložit v košarico',
        buy: 'Kupiti',
        order: 'Naroči ',
        remove: 'Izbriši',
      },
      cart: {
        total: {
          label: 'Skupaj',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Skupaj po vrsticah',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Skupaj za prevoz',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Skupaj za plačilo',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Skupaj davek',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Ponastavi košarico',
      },
      messages: {
        confirmResetCart: 'Ali ste prepričani, da želite izbrisati vsebino košarice?',
        giftClaim: 'Lahko dobite {quantity} kosov {productName} kot darilo.',
        cartManipulations: {
          allRemoved: 'Vsi artikli so bili odstranjeni iz košarice.',
          itemAdded: '{number, plural, one {V košarico je dodan artikel} other {Artikli so dodani v košarico}}.',
          itemRemoved:
            '{number, plural, one {Artikel odstranjen iz košarice} other {Artikli so odstranjeni iz košarice}}.',
          quantityChaged: 'Kupljena količina se je spremenila.',
        },
      },
      components: {
        appBartActionCart: 'Košarica',
        quantityField: {
          states: {
            quantityRounded: 'Količina je bila zaokrožena na najbližjo velikost paketa.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Vaša košarica je prazna',
        },
        cartAfterAddModal: {
          continueShopping: 'Nadaljuj z nakupom',
          goToCart: 'Pojdi v košarico',
          title: 'Izdelki so dodani v košarico',
          pcs: 'ks',
        },
      },
    },
  },
};

export default messages;
